var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card',{attrs:{"title":"Users Table"}},[_c('div',{staticClass:"\n      custom-search\n      d-flex\n      justify-content-end justify-content-sm-start\n      mb-2 mb-sm-0\n    "},[_c('div',{staticClass:"d-flex align-items-center"},[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(113, 102, 240, 0.15)'),expression:"'rgba(113, 102, 240, 0.15)'",modifiers:{"400":true}}],staticClass:"float-right",attrs:{"id":"show-btn","variant":"primary","to":{ name: 'create-user' }}},[_vm._v("Add User")])],1)]),_c('div',{staticClass:"custom-search d-flex justify-content-end"},[_c('b-form-group',[_c('div',{staticClass:"d-flex align-items-center"},[_c('label',{staticClass:"mr-1"},[_vm._v("Search")]),_c('b-form-input',{staticClass:"d-inline-block",attrs:{"placeholder":"Search","type":"text","debounce":"1500"},model:{value:(_vm.params.query),callback:function ($$v) {_vm.$set(_vm.params, "query", $$v)},expression:"params.query"}})],1)])],1),_c('Modal',{attrs:{"text":_vm.modalOptions.text,"ok-button":_vm.modalOptions.okButton,"cancel-button":_vm.modalOptions.cancelButton,"show":_vm.show},on:{"result":_vm.modalOptions.modalResult,"hidden":function($event){_vm.show = false}}}),_c('vue-good-table',{attrs:{"mode":"remote","pagination-options":{
      enabled: true,
    },"totalRows":_vm.totalRecords,"rows":_vm.rows,"columns":_vm.columns,"isLoading":_vm.isLoading},on:{"on-page-change":_vm.onPageChange,"on-sort-change":_vm.onSortChange,"on-per-page-change":_vm.onPerPageChange,"update:isLoading":function($event){_vm.isLoading=$event},"update:is-loading":function($event){_vm.isLoading=$event}},scopedSlots:_vm._u([{key:"table-row",fn:function(props){return [(props.column.field === 'action')?_c('span',[_c('span',[_c('b-dropdown',{attrs:{"variant":"link","toggle-class":"text-decoration-none","no-caret":""},scopedSlots:_vm._u([{key:"button-content",fn:function(){return [_c('feather-icon',{staticClass:"text-body align-middle mr-25",attrs:{"icon":"MoreVerticalIcon","size":"16"}})]},proxy:true}],null,true)},[_c('b-dropdown-item',{attrs:{"to":{
                name: 'update-user',
                params: { id: props.row._id },
              }}},[_c('span',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(113, 102, 240, 0.15)'),expression:"'rgba(113, 102, 240, 0.15)'",modifiers:{"400":true}}],attrs:{"variant":"outline-primary"}},[_vm._v(" Edit ")])]),_c('b-dropdown-item',{on:{"click":function($event){return _vm.showResetPasswordPopup(props.row)}}},[_c('span',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(113, 102, 240, 0.15)'),expression:"'rgba(113, 102, 240, 0.15)'",modifiers:{"400":true}}],attrs:{"variant":"outline-primary"}},[_vm._v(" Reset the password ")])]),_c('b-dropdown-item',{on:{"click":function($event){return _vm.showRemovePopup(props.row)}}},[_c('span',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(113, 102, 240, 0.15)'),expression:"'rgba(113, 102, 240, 0.15)'",modifiers:{"400":true}}],attrs:{"variant":"outline-primary"}},[_vm._v(" Delete ")])]),_c('b-dropdown-item',{on:{"click":function($event){return _vm.showBlockUser(props.row)}}},[_c('span',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(113, 102, 240, 0.15)'),expression:"'rgba(113, 102, 240, 0.15)'",modifiers:{"400":true}}],attrs:{"variant":"outline-primary"}},[_vm._v(" "+_vm._s(!props.row.isBlocked ? "Block" : "Activate")+" ")])])],1)],1)]):_vm._e(),(props.column.field === 'Topics')?_c('span',{staticClass:"text-nowrap"},_vm._l((props.row.topics),function(topic){return _c('p',{key:topic._id},[_vm._v(" "+_vm._s(topic.name)+" ")])}),0):_vm._e(),(props.column.field === 'Language')?_c('span',{staticClass:"text-nowrap"},_vm._l((props.row.languages),function(language){return _c('p',{key:language._id},[_vm._v(" "+_vm._s(language.name)+" ")])}),0):_vm._e(),(props.column.field === 'Children')?_c('span',{staticClass:"text-nowrap"},_vm._l((props.row.children),function(children){return _c('p',{key:children._id},[_vm._v(" "+_vm._s(children.name)+" ")])}),0):_vm._e(),(
          props.column.field === 'LifeStage' &&
          props.row.lifeStage !== undefined
        )?_c('span',{staticClass:"text-nowrap"},_vm._l((props.row.lifeStage),function(lifeStage){return _c('p',{key:lifeStage._id},[_vm._v(" "+_vm._s(lifeStage.name)+" ")])}),0):_vm._e(),(props.column.field === 'IsBlocked')?_c('span',{staticClass:"text-nowrap"},[_vm._v(" "+_vm._s(props.row.isBlocked ? "Blocked" : "Active")+" ")]):_vm._e(),(props.column.field === 'CompleateProfile')?_c('span',{staticClass:"text-nowrap"},[_vm._v(" "+_vm._s(props.row.compleateProfile ? "Completed" : "Missing info")+" ")]):_c('span',[_vm._v(" "+_vm._s(props.formattedRow[props.column.field])+" ")])]}},{key:"emptystate",fn:function(){return [_c('p',{staticClass:"text-center"},[_vm._v(" "+_vm._s(_vm.isOPen ? "Loading..." : "This will show up when there are no rows")+" ")])]},proxy:true}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }