<template>
  <b-card title="Users Table">
    <div
      class="
        custom-search
        d-flex
        justify-content-end justify-content-sm-start
        mb-2 mb-sm-0
      "
    >
      <div class="d-flex align-items-center">
        <b-button
          id="show-btn"
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          class="float-right"
          variant="primary"
          :to="{ name: 'create-user' }"
          >Add User</b-button
        >
      </div>
    </div>
    <!-- search input -->
    <div class="custom-search d-flex justify-content-end">
      <b-form-group>
        <div class="d-flex align-items-center">
          <label class="mr-1">Search</label>
          <b-form-input
            v-model="params.query"
            placeholder="Search"
            type="text"
            class="d-inline-block"
            debounce="1500"
          />
        </div>
      </b-form-group>
    </div>

    <Modal
      :text="modalOptions.text"
      :ok-button="modalOptions.okButton"
      :cancel-button="modalOptions.cancelButton"
      :show="show"
      @result="modalOptions.modalResult"
      @hidden="show = false"
    />

    <!-- table -->
    <vue-good-table
      mode="remote"
      :pagination-options="{
        enabled: true,
      }"
      :totalRows="totalRecords"
      :rows="rows"
      :columns="columns"
      @on-page-change="onPageChange"
      @on-sort-change="onSortChange"
      @on-per-page-change="onPerPageChange"
      :isLoading.sync="isLoading"
    >
      <template slot="table-row" slot-scope="props">
        <!-- Column: Action -->
        <span v-if="props.column.field === 'action'">
          <span>
            <b-dropdown
              variant="link"
              toggle-class="text-decoration-none"
              no-caret
            >
              <template v-slot:button-content>
                <feather-icon
                  icon="MoreVerticalIcon"
                  size="16"
                  class="text-body align-middle mr-25"
                />
              </template>
              <b-dropdown-item  :to="{
                  name: 'update-user',
                  params: { id: props.row._id },
                }">
                <span
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  variant="outline-primary"
                >
                  Edit
                </span>
              </b-dropdown-item>
              <b-dropdown-item @click="showResetPasswordPopup(props.row)">
                <span
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  variant="outline-primary"
                >
                  Reset the password
                </span>
              </b-dropdown-item>

              <b-dropdown-item @click="showRemovePopup(props.row)">
                <span
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  variant="outline-primary"
                >
                  Delete
                </span>
              </b-dropdown-item>
              <b-dropdown-item @click="showBlockUser(props.row)">
                <span
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  variant="outline-primary"
                >
                  {{ !props.row.isBlocked ? "Block" : "Activate" }}
                </span>
              </b-dropdown-item>
            </b-dropdown>
          </span>
        </span>

        <span v-if="props.column.field === 'Topics'" class="text-nowrap">
          <p v-for="topic in props.row.topics" :key="topic._id">
            {{ topic.name }}
          </p>
        </span>

        <span v-if="props.column.field === 'Language'" class="text-nowrap">
          <p v-for="language in props.row.languages" :key="language._id">
            {{ language.name }}
          </p>
        </span>

        <span v-if="props.column.field === 'Children'" class="text-nowrap">
          <p v-for="children in props.row.children" :key="children._id">
            {{ children.name }}
          </p>
        </span>

        <span
          v-if="
            props.column.field === 'LifeStage' &&
            props.row.lifeStage !== undefined
          "
          class="text-nowrap"
        >
          <p v-for="lifeStage in props.row.lifeStage" :key="lifeStage._id">
            {{ lifeStage.name }}
          </p>
        </span>

        <span v-if="props.column.field === 'IsBlocked'" class="text-nowrap">
          {{ props.row.isBlocked ? "Blocked" : "Active" }}
        </span>

        <span
          v-if="props.column.field === 'CompleateProfile'"
          class="text-nowrap"
        >
          {{ props.row.compleateProfile ? "Completed" : "Missing info" }}
        </span>

        <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>
      <template #emptystate>
        <p class="text-center">
          {{
            isOPen ? "Loading..." : "This will show up when there are no rows"
          }}
        </p>
      </template>
    </vue-good-table>
  </b-card>
</template>

<script>
import {
  BAvatar,
  BBadge,
  BPagination,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BDropdownItem,
  BDropdown,
  BCard,
  BButton,
} from "bootstrap-vue";
import { VueGoodTable } from "vue-good-table";
import "prismjs";
import Modal from "@/views/components/shared/Modal/Modal";
import "prismjs/themes/prism-tomorrow.css";
import Prism from "vue-prism-component";
import store from "@/store/index";
import Ripple from "vue-ripple-directive";
import UserService from "@/services/UserService";
export default {
  name: "UserList",
  mixins: [],
  components: {
    VueGoodTable,
    BAvatar,
    BBadge,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    Prism,
    Modal,
    BDropdownItem,
    BDropdown,
    BCard,
    BButton,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      params: {
        page: 1,
        limit: 10,
        sort_keys: undefined,
        sort_dir: undefined,
        query: undefined,
      },
      columns: [
        { label: "Name", field: "name" },
        { label: "Username", field: "username" },
        { label: "Email", field: "email" },
        { label: "Age", field: "age" },
        { label: "Life stage", field: "LifeStage" },
        { label: "Kids", field: "Children" },
        { label: "Needs", field: "Topics" },
        { label: "Location", field: "location" },
        { label: "Language", field: "Language" },
        { label: "Work life", field: "workLife" },
        { label: "Profile", field: "CompleateProfile", sortable: false },
        { label: "Status", field: "IsBlocked" },
        { label: "Action", field: "action", sortable: false },
      ],
      rows: [],
      isLoading: undefined,
      isOPen: true,
      totalRecords: undefined,
      userService: new UserService(),
      modalOptions: {
        modalResult: this.modalResultOfWeek,
      },
      show: false,
    };
  },
  watch: {
    params: {
      handler: function () {
        this.getUsers();
      },
      deep: true,
    },
  },
  computed: {
    direction() {
      if (store.state.appConfig.isRTL) {
        this.dir = true;
        return this.dir;
      }
      this.dir = false;
      return this.dir;
    },
  },
  created() {
    this.getUsers();
  },
  methods: {
    client(rowObj) {
      return `${rowObj.client.first_name} ${rowObj.client.last_name}`;
    },
    onPerPageChange(page) {
      this.params.page = 1;
      this.params.limit = page.currentPerPage;
    },
    onPageChange(active) {
      this.params.page = active.currentPage;
      this.params.limit = active.currentPerPage;
    },
    onSortChange(params) {
      const SortKey =
        typeof params[0].field === "function" ? "client" : params[0].field;
      this.params.sort_keys = [SortKey[0].toLowerCase() + SortKey.slice(1)];
      this.params.sort_dir = [params[0].type];
    },
    getUsers() {
      this.isLoading = true;
      this.userService
        .index(this.params)
        .then((res) => {
          this.rows = res.users.map((el) => {
            if (el.lifeStage !== undefined) {
              el.lifeStage = this.getLifeStage(el.lifeStage, el.children);
            }
            return {
              ...el,
            };
          });
          this.totalRecords = res.count;
        })
        .catch((err) => {})
        .finally(() => {
          this.isLoading = false;
          this.isOPen = false;
        });
    },
    getLifeStage(lifeStage, childrens) {
      var LifeStageArray = [];
      if (lifeStage.beParent != false)  LifeStageArray.push({ name: "Want to be a parent" });
      if (lifeStage.parentToBe !== undefined)
        LifeStageArray.push({ name: "Parent To Be" });
      if (lifeStage.noKids != false) LifeStageArray.push({ name: "No Kids" });
      if (childrens !== undefined && childrens.length > 0){
        childrens.forEach(element => {
          if(element.typeOfParent == "grandparentType") {
            if(!LifeStageArray.find(e => e.name == "Grandparent")) LifeStageArray.push({ name: "Grandparent" }); 
          }else {
            if(!LifeStageArray.find(e => e.name == "Parent")) LifeStageArray.push({ name: "Parent" });
          }
        });
      }
      return LifeStageArray;
    },
    showBlockUser(model) {
      this.show = true;
      this.model = model;
      this.modalOptions = {
        text: `Are you sure you want to ${
          !model.isBlocked ? "Block" : "Activate"
        } this user ?`,
        okButton: `${!model.isBlocked ? "Block" : "Activate"}`,
        cancelButton: "Cancel",
        clickedId: model._id,
        currentAction: "Block",
        modalResult: this.modalResultOfBlock,
      };
    },
    showResetPasswordPopup(model) {
      this.show = true;
      this.model = model;
      this.modalOptions = {
        text: `Are you sure you want to reset password to this user ?`,
        okButton: `Reset`,
        cancelButton: "Cancel",
        clickedId: model._id,
        currentAction: "",
        modalResult: this.modalResultOfResetPassword,
      };
    },
    showRemovePopup(model) {
      this.show = true;
      this.show = true;
      this.model = model;
      this.modalOptions = {
        text: `Are you sure you want to delete this user ?`,
        okButton: `Delete`,
        cancelButton: "Cancel",
        clickedId: model._id,
        currentAction: "Block",
        modalResult: this.modalResultRemove,
      };
    },
    modalResultRemove(value) {
      this.show = false;
      if (value) {
        this.deleteUser(this.model._id);
      }
    },
    deleteUser(id) {
      this.isLoading = true;
      this.userService
        .delete(id)
        .then((res) => {
          this.$router.go();
        })
        .catch((err) => {})
        .finally(() => {
          this.isLoading = false;
        });
    },
    modalResultOfBlock(value) {
      this.show = false;
      if (value) {
        this.blockUser(this.model._id, this.model.isBlocked);
      }
    },
    modalResultOfResetPassword(value) {
      this.show = false;
      if (value) {
        this.resetPasswordUser(this.model.email);
      }
    },
    blockUser(id, isBlocked) {
      this.isLoading = true;
      this.userService
        .makeBocked({ id: id, isBlocked: isBlocked })
        .then((res) => {
          this.$router.go();
        })
        .catch((err) => {})
        .finally(() => {
          this.isLoading = false;
        });
    },
    resetPasswordUser(email) {
      if (email != null) {
        this.isLoading = true;
        this.userService
          .resetPassword({email: email})
          .then((res) => {
            this.$router.go();
          })
          .catch((err) => {})
          .finally(() => {
            this.isLoading = false;
          });
      }
    },
    remove(id) {},
  },
};
</script>
<style>
.loader-overlay {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 99;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: wait;
}

@media (min-width: 576px) {
  .justify-content-sm-start {
    justify-content: flex-start !important;
  }
}
@media (max-width: 576px) {
  th,
  td {
    /* font size */
    font-size: 0.857rem;
    font-weight: 400;
    /* text center horizontally */
    vertical-align: middle !important;
    text-align: center;
    line-height: 20px;
  }
}
.text-block {
  white-space: pre;
}
</style>