<template>
  <div class="">
    <UserList/>
  </div>
</template>

<script>
import UserList from "@/views/components/user/UserList";
import GoodTableSsr from "@/views/table/vue-good-table/GoodTableSsr";
import '@core/scss/vue/libs/vue-good-table.scss';
export default {
  name: "Index",
  components: {
    UserList,
    GoodTableSsr
  }
}
</script>

<style scoped lang="scss">

</style>
