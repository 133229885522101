import CrudService from "@/services/shared/http/CrudService";

export default class UserService extends CrudService {
  constructor(params = {}) {
    super("user", params);
  }
  index(params = {}) {
    return this.api.GET("user/get-all-user", params)
  }
  makeBocked(data, params = {}) {
    return this.api.POST('user/block-user', data, params)
  }
  resetPassword(data, params = {}) {
    return this.api.POST('auth/reset-password-from-app', data, params)
  }
  create(data, params = {}) {
    return this.api.POST('auth/create-user-from-admin', data, params)
  }
  update(id, data, params = {}) {
    return this.api.POST('user/' + id, data, params)
  }
  changePassword(formData) {
    return this.api.POST('auth/change-password-from-app', formData)
  }
  delete(id, params = {}) {
    return this.api.DELETE('auth' + '/' + id, params)
  }
}
